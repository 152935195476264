<template>
  <v-menu bottom offset-y left offset-x close-on-click min-width="150px">
    <template v-slot:activator="{ on, attrs }">
      <v-app-bar-nav-icon v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
    </template>

    <v-list class="text-right">
      <v-list-item v-for="(item, index) in menuItems" :key="index" link exact :to="{ name: item.link }">
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'AdminToolbarMenu',
  props: {
    menuItems: { Type: Array, Required: true }
  }
}
</script>

<style></style>
